<template>
  <div class="conversion">
    <div class="conversion-box">
      <div class="tit">{{ $t('my_enhance_1') }}</div>
      <el-form
        :model="ruleForm"
        :hide-required-asterisk="true"
        label-width="120px"
        size="small"
        ref="ruleForm"
        class="demo-ruleForm"
      >
        <el-form-item :label="$t('my_enhance_2')">
          <el-input v-model="ruleForm.all_money" disabled>
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('my_enhance_3')">
          <el-input v-model="ruleForm.shengyu" disabled>
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('本次兑换额度： ')">
          <el-input v-model="ruleForm.surname" disabled>
            <template slot="append">$</template>
          </el-input>
        </el-form-item> -->
        <el-form-item
          :label="$t('my_enhance_4')"
          :rules="[
            {
              required: true,
              message: $t('my_enhance_5'),
              trigger: 'blur',
            },
          ]"
          prop="money"
        >
          <el-input
            v-model="ruleForm.money"
            @input="amountBlur"
            :placeholder="$t('my_enhance_6')"
          >
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <el-form-item :label="$t('my_enhance_7')">
          <el-input v-model="ruleForm.amount_fee" disabled>
            <template slot="append">$</template>
          </el-input>
        </el-form-item>
        <el-form-item label="" style="text-align: center">
          <el-button
            class="form-login"
            :loading="loading"
            type="primary"
            @click="submitPay"
          >
            {{ $t('立即提交') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <usdPayment
      :payData="payData"
      :isshow.sync="isUsdPayment"
      @updateData="updateDataFu"
    ></usdPayment>
  </div>
</template>

<script>
import usdPayment from '@/components/pay/usdPayment.vue'
export default {
  components: {
    usdPayment,
  },
  data() {
    return {
      ruleForm: {},
      payData: {
        type: 5,
      },
      loading: false,
      isUsdPayment: false,
    }
  },
  created() {
    this.getMoney()
  },
  methods: {
    updateDataFu() {
      let that = this
      this.getMoney()
      this.$refs.ruleForm.resetFields()
      this.$alert(that.$t('my_enhance_9'), that.$t('my_enhance_10'), {
        confirmButtonText: that.$t('my_enhance_11'),
      })
    },
    async amountBlur() {
      if (!this.ruleForm.money) {
        this.$set(this.ruleForm, 'amount_fee', 0)
        return
      }
      let res = await this.$axios.post('/api/Exchange/applyRate', {
        money: this.ruleForm.money,
      })
      if (res.code === 1) {
        this.$set(this.ruleForm, 'amount_fee', res.data)
      }
    },
    async getMoney() {
      let res = await this.$axios.post('/api/Exchange/money')
      if (res.code === 1) {
        this.ruleForm = res.data
      }
    },
    submitPay() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let res = await this.$axios.post('/api/Exchange/apply', {
            money: this.ruleForm.money,
          })
          this.loading = false
          if (res.code === 1) {
            this.payData = {
              ...this.payData,
              ...res.data,
            }
            this.isUsdPayment = true
            // if (this.ruleForm.amount_fee) {
            //   this.isUsdPayment = true
            // } else {
            //   this.$message({
            //     message: res.msg,
            //     type: 'success',
            //   })
            //   this.updateDataFu()
            // }
          }
        }
      })
    },
    saveEnhance() {},
  },
}
</script>

<style scoped lang="scss">
.conversion {
  padding-bottom: 20px;
  width: 500px;
  margin: 0 auto;
  margin-top: 30px;
}
.conversion-box {
  margin-right: 50px;
  .tit {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    letter-spacing: 3px;
    margin-bottom: 30px;
    text-align: center;
  }
  .el-select {
    width: 100%;
  }
  /deep/ .el-radio-group {
    width: 100%;
    .el-radio-button {
      width: 50%;
      .el-radio-button__inner {
        width: 100%;
      }
    }
  }
}
.conversion-tip {
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  background: #bbbbbb;
  font-size: 12px;
  padding: 0 8px;
  text-align: center;
  margin-bottom: 20px;
  &.red {
    background: #f40b0b;
  }
  .addr {
    color: #409eff;
    cursor: pointer;
    margin-left: 6px;
  }
}
</style>
